@import "../../static/scss/colors";
@import "../../static/scss/mixin";

.landingPage {
  height: 0;
  flex: 1;
  display: flex;
  .pageWrap {
    width: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    .pageWrapIn {
      width: 100%;
      // flex: 1;
      // display: flex;
      // flex-direction: column;
      // position: relative;
      // overflow-y: auto;
      .navDetail {
        background-color: $primary-color;
        display: none;
        flex-direction: column;
        position: relative;
        margin-bottom: 100px;
        .navDetailTexts {
          flex: 1;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          color: white;
          overflow: hidden;
          .title {
            font-size: 43px;
            font-weight: 600;
          }
        }
        .spacer {
          flex: 0 0 70px;
        }
        .searcWrap {
          position: absolute;
          left: 0;
          right: 0;
          bottom: 0;
          margin: auto;
          height: 80px;
          width: 50%;
          border-radius: 20px;
          border: none;
          background: #f8f8f8;
          border: 1px solid $border-color;
          margin-bottom: -40px;
          display: flex;
          align-items: center;
          .input {
            height: 100%;
            width: 100%;
            border: none;
            background: none;
            font-size: 20px;
            font-weight: 600;
          }
          .icon {
            height: 40px;
            width: 40px;
            margin: auto 20px;
            opacity: 0.5;
            cursor: pointer;
            transition: all 400ms ease;
            &:hover {
              transform: scale(1.1);
            }
          }
        }
      }
      .title {
        font-size: 28px;
        margin: 30px 40px;
        font-weight: 600;
      }
      .cardListWrap {
        flex: 0 0 40%;
        display: flex;
        .cardList {
          width: 0;
          flex: 1;
          height: fit-content;
          padding: 0 40px;
          display: flex;
          overflow-x: auto;
          margin-bottom: 70px;
          .card {
            width: 400px;
            flex: 0 0 400px;
            border: 1px solid $border-color;
            border-radius: 20px;
            margin-right: 30px;
            display: flex;
            flex-direction: column;
            overflow: hidden;
            cursor: pointer;
            transition: all 400ms ease;
            &:hover {
              transform: scale(0.95);
            }
            .headerArea {
              flex: 0 0 120px;
              background-color: $app-color;
              margin-bottom: 65px;
              position: relative;
              .img {
                height: 90px;
                width: 90px;
                border-radius: 10px;
                position: absolute;
                border: 1px solid $border-color;
                left: 30px;
                bottom: -45px;
                margin: auto;
                background: white;
                padding: 15px;
                img {
                  height: 100%;
                  width: 100%;
                }
              }
            }
            .headerPreview {
              display: flex;
              flex-direction: column;
              padding: 10px 30px;
              .title {
                margin: 0;
                font-size: 22px;
                font-weight: 600;
                margin-bottom: 6px;
              }
              .by {
                display: flex;
                font-size: 17;
                align-items: center;
                transition: all 400 ms ease;
                cursor: pointer;
                .byImg {
                  height: 16px;
                  width: 16px;
                  object-fit: contain;
                  margin: 5px;
                }
                &:hover {
                  transform: scale(1.01);
                }
              }
            }
            .content {
              padding: 30px;
              display: flex;
              .price,
              .supply {
                padding: 10px 0 15px 0;
                flex: 0 0 50%;
                .label {
                  font-size: 14px;
                  line-height: 2;
                }
                .value {
                  font-size: 20px;
                  font-weight: bold;
                  small {
                    font-size: 14px;
                    font-weight: 500;
                  }
                }
              }
              .supply {
                text-align: right;
                border-left: 1px solid $border-color;
              }
            }
            .footer {
              padding: 30px;
              display: flex;
              justify-content: space-between;
              align-items: center;
              .country,
              .circulation {
                font-size: 20px;
                font-weight: 600;
                display: flex;
                align-items: center;
                margin: 0;
                cursor: pointer;
                transition: all 400 ms ease;
                img {
                  height: 19px;
                  width: 19px;
                  object-fit: contain;
                  margin-right: 5px;
                }
                &:hover {
                  transform: scale(1.02);
                }
              }
            }
          }
        }
      }
      .eventsBlogs {
        padding: 30px 40px;
        background: $primary-color;
        .eventsBlogsTitle {
          font-size: 28px;
          font-weight: 600;
          color: white;
        }
        .eventsBlogsList {
          padding: 30px 0;
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          .eventsBlogCard {
            flex: 0 0 32%;
            height: 350px;
            background: white;
            margin: 20px 0;
            border-radius: 15px;
            border: 1px solid $border-color;
            @include btn-animate;
          }
        }
      }
      .footerCopyright {
        border-top: 1px solid $border-color;
        flex: 0 0 65px;
        background: $primary-color;
        padding: 0 40px;
        display: flex;
        align-items: center;
        color: white;
      }
    }
  }
  @include mdportrait {
    .pageWrap {
      .pageWrapIn {
        .navDetail {
          display: flex;
          padding-top: 30px;
          .navDetailTexts {
            padding: 0 40px;
            .title {
              font-size: 22px;
              text-align: center;
              margin: 20px 0;
            }
          }
          .spacer {
            flex: 0 0 60px;
          }
          .searcWrap {
            height: 60px;
            width: 80%;
            border-radius: 10px;
            margin-bottom: -30px;
            display: flex;
            .input {
              width: 0;
              flex: 1;
              font-size: 16px;
              order: 0;
              padding: 0 10px;
            }
            .icon {
              order: 1;
              height: 30px;
              width: 20px;
              margin: auto 10px;
              opacity: 1;
            }
            .spacerLine,
            span {
              display: none;
            }
          }
        }
        .cardListWrap {
          flex: unset;
          flex-direction: column;
          .cardList {
            width: 100%;
            overflow-y: auto;
            flex: unset;
            flex-direction: column;
            .card {
              width: 100%;
              flex: unset;
              margin-right: 0;
              margin-bottom: 20px;
              border-radius: 40px;
              .headerArea {
                flex: 0 0 90px;
                background-color: $app-color;
                margin-bottom: 45px;
                position: relative;
                .img {
                  left: 0;
                  right: 0;
                  bottom: -45px;
                  border-radius: 45px;
                }
              }
              .headerPreview {
                display: flex;
                justify-content: center;
                align-items: center;
                .title {
                  font-size: 22px;
                  text-align: center;
                }
                .by {
                  font-size: 18;
                  .byImg {
                    height: 17px;
                    width: 17px;
                  }
                }
              }
              .content {
                .price,
                .supply {
                  .label {
                    font-size: 14px;
                  }
                  .value {
                    font-size: 17px;
                    small {
                      font-size: 15px;
                    }
                  }
                }
              }
              .footer {
                padding: 20px 30px;
                .country,
                .circulation {
                  font-size: 18px;
                  img {
                    height: 17px;
                    width: 17px;
                  }
                }
              }
            }
          }
        }
        .eventsBlogs {
          .eventsBlogsTitle {
            text-align: center;
          }
          .eventsBlogsList {
            padding: 30px 0;
            flex-direction: column;
            .eventsBlogCard {
              flex: 0 0 190px;
            }
          }
        }
        .footerMenu {
          display: flex;
          flex-direction: column;
          .subMenu {
            height: 400px;
            flex: 0 0 33.33%;
            border-left: none;
            border-top: 1px solid $border-color;
            padding: 40px;
            &:first-child {
              border-top: none;
            }
            .menuItem {
              font-size: 20px;
            }
          }
        }
      }
    }
  }
}
