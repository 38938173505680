@import "../../static/scss/colors";
@import "../../static/scss/mixin";

.trending {
  padding: 30px 0;
  height: 450px;
  min-height: 450px;
  overflow: hidden;
  z-index: 0;
  .trendingTitle {
    font-size: 28px;
    font-weight: 600;
    text-align: center;
  }
  .trendingSubtitle {
    margin: 10px 0;
    text-align: center;
  }
  .card {
    background: white;
    width: 300px;
    border: 1px solid $border-color;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    cursor: pointer;
    transition: all 400ms ease;
    flex: unset;
    margin: 0;
    border-radius: 40px;
    .headerArea {
      flex: 0 0 70px;
      background-color: $app-color;
      margin-bottom: 45px;
      position: relative;
      .img {
        height: 70px;
        width: 70px;
        border-radius: 10px;
        position: absolute;
        border: 1px solid $border-color;
        margin: auto;
        background: white;
        padding: 15px;
        left: 0;
        right: 0;
        bottom: -35px;
        border-radius: 35px;
        img {
          height: 100%;
          width: 100%;
        }
      }
    }
    .headerPreview {
      display: flex;
      flex-direction: column;
      padding: 10px 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      .title {
        margin: 0;
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 6px;
        text-align: center;
      }
      .by {
        display: flex;
        font-size: 15;
        align-items: center;
        transition: all 400 ms ease;
        cursor: pointer;
        .byImg {
          height: 16px;
          width: 16px;
          object-fit: contain;
          margin: 5px;
        }
      }
    }
    .content {
      padding: 10px 30px;
      display: flex;
      .price,
      .supply {
        padding: 10px 0 15px 0;
        flex: 0 0 50%;
        .label {
          font-size: 12px;
          line-height: 2;
        }
        .value {
          font-size: 15px;
          font-weight: bold;
          small {
            font-size: 12px;
            font-weight: 500;
          }
        }
      }
      .supply {
        text-align: right;
        border-left: 1px solid $border-color;
      }
    }
    .footer {
      padding: 20px 30px;
      padding-top: 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .country,
      .circulation {
        font-size: 17px;
        font-weight: 600;
        display: flex;
        align-items: center;
        margin: 0;
        cursor: pointer;
        transition: all 400 ms ease;
        img {
          height: 16px;
          width: 16px;
          object-fit: contain;
          margin-right: 5px;
        }
        &:hover {
          transform: scale(1.02);
        }
      }
    }
  }
}
