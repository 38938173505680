@import "../../static/scss/colors";
@import "../../static/scss/mixin";

.explore {
  padding: 100px 40px;
  background: #f8f8f8;
  .expTitle {
    font-size: 28px;
    font-weight: 600;
  }
  .hubList {
    padding: 30px 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .hubCard {
      flex: 0 0 14%;
      height: 300px;
      margin: 20px 0;
      border-radius: 15px;
      background-size: cover;
      overflow: hidden;
      border: 1px solid $border-color;
      display: flex;
      flex-direction: column;
      @include btn-animate($primary-color, 80%);
      img {
        height: 0;
        flex: 1;
        object-fit: contain;
        padding: 20%;
      }
      .footerText {
        flex: 0 0 50px;
        background: #4caf50;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 20px;
        font-weight: bold;
        color: white;
      }
    }
  }
  @include mdportrait {
    .expTitle {
      text-align: center;
    }
    .hubList {
      .hubCard {
        min-width: 160px;
        height: 180px;
        .footerText {
          flex: 0 0 40px;
          font-size: 15px;
        }
      }
    }
  }
}
