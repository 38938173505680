@import "../../static/scss/colors";
@import "../../static/scss/mixin";

.myTicketsPage {
  height: 0;
  flex: 1;
  display: flex;
  .pageContent {
    width: 0;
    flex: 1;
    display: flex;
    flex-direction: column;
    .header {
      padding: 50px 90px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .texts {
        .titleHead {
          font-size: 35px;
          font-weight: bold;
        }
        .subTitleHead {
          font-size: 20px;
          font-weight: 500;
        }
      }
      .menu {
        display: flex;
        align-items: center;
        .menuItem {
          font-size: 18px;
          font-weight: 500;
          margin-left: 20px;
          cursor: pointer;
          &.true {
            font-weight: 700;
          }
        }
      }
    }
    .headTwo {
      padding: 0 90px;
      font-size: 20px;
      font-weight: bold;
      display: flex;
      justify-content: space-between;
      margin: 30px 0;
    }
    .headTwoMob {
      display: none;
    }
    .tokenList {
      padding: 0 90px;
      padding-bottom: 30px;
      display: flex;
      .tokenListIn {
        width: 0;
        flex: 1;
        display: flex;
        overflow-x: auto;
        .card {
          width: 400px;
          border: 1px solid $border-color;
          border-radius: 20px;
          margin-right: 30px;
          display: flex;
          flex-direction: column;
          overflow: hidden;
          .headerArea {
            flex: 0 0 90px;
            background-color: $app-color;
            margin-bottom: 45px;
            position: relative;
            .img {
              height: 90px;
              width: 90px;
              border-radius: 45px;
              position: absolute;
              border: 1px solid $border-color;
              left: 0;
              right: 0;
              bottom: -45px;
              margin: auto;
              background: white;
              padding: 10px;
              img {
                height: 100%;
                width: 100%;
              }
            }
          }
          .headerPreview {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 10px;
            .title {
              font-size: 26px;
              font-weight: 600;
            }
          }
          .content {
            padding: 0 40px;
            .detailItem {
              display: flex;
              justify-content: space-between;
              margin: 30px 0;
              .label {
                font-size: 19px;
              }
              .value {
                font-size: 19px;
                font-weight: bold;
              }
            }
          }
          .footer {
            flex: 0 0 70px;
            border-top: 1px solid $border-color;
            display: flex;
            .ftBtn {
              flex: 0 0 50%;
              border-left: 1px solid $border-color;
              display: flex;
              justify-content: center;
              align-items: center;
              font-size: 18px;
              font-weight: bold;
              @include btn-animate;
              &:first-of-type {
                border: none;
              }
            }
          }
        }
      }
    }
  }
  @include mdportrait {
    .pageContent {
      .header {
        padding: 20px 30px;
        .texts {
          .titleHead {
            font-size: 25px;
          }
          .subTitleHead {
            font-size: 16px;
          }
        }
        .menu {
          display: none;
        }
      }
      .headTwo {
        display: none;
      }
      .headTwoMob {
        display: flex;
        padding: 15px 30px;
        font-size: 18px;
        margin: 30px 0 20px 0;
        font-weight: 600;
      }
      .tokenList {
        padding: 0 30px;
        padding-bottom: 30px;
        .tokenListIn {
          .card {
            flex: 0 0 300px;
            width: 300px;
            .headerArea {
              flex: 0 0 60px;
              margin-bottom: 35px;
              .img {
                height: 60px;
                width: 60px;
                border-radius: 30px;
                bottom: -30px;
                padding: 8px;
              }
            }
            .headerPreview {
              padding: 0px;
              .title {
                text-align: center;
                font-size: 20px;
              }
            }
            .content {
              padding: 0 30px;
              .detailItem {
                margin: 20px 0;
                .label {
                  font-size: 16px;
                }
                .value {
                  font-size: 16px;
                }
              }
            }
            .footer {
              flex: 0 0 50px;
              .ftBtn {
                font-size: 16px;
              }
            }
          }
        }
      }
    }
  }
}
