@import "../../static/scss/colors";
@import "../../static/scss/mixin";

.cartPage {
  height: 0;
  flex: 1;
  display: flex;
  .pageContent {
    width: 0;
    flex: 1;
    display: flex;
    flex-direction: column;
    .topBar {
      flex: 0 0 100px;
      max-height: 90px;
      border-bottom: 1px solid $border-color;
      padding: 0 90px;
      display: flex;
      align-items: center;
      .navLogo {
        height: 50%;
        width: auto;
      }
    }
    .header {
      padding: 50px 90px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .texts {
        .titleHead {
          font-size: 35px;
          font-weight: bold;
        }
        .subTitleHead {
          font-size: 20px;
          font-weight: 500;
        }
      }
      .btnAsset {
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid $border-color;
        padding: 10px 15px;
        border-radius: 10px;
        img {
          height: 25px;
          width: auto;
        }
        span {
          font-size: 22px;
          font-weight: bold;
          margin-left: 10px;
        }
      }
    }
    .cartList {
      height: 0;
      flex: 1;
      overflow-y: auto;
      display: flex;
      flex-direction: column;
      .cartItmCard {
        flex: 0 0 160px;
        height: 160px;
        max-height: 160px;
        border-radius: 20px;
        border: 1px solid $border-color;
        margin: 20px 80px;
        display: flex;
        align-items: center;
        padding: 0 60px;
        position: relative;
        .coinDetail {
          display: flex;
          align-items: center;
          img {
            height: 80px;
            width: 80px;
            object-fit: contain;
            margin-right: 20px;
          }
          .cardText {
            .name {
              font-size: 26px;
              font-weight: 600;
            }
            .symbool {
              font-size: 20px;
              font-weight: 600;
              color: $text-fade-color;
            }
            .amountMobile {
              display: none;
            }
          }
        }
        .count {
          display: flex;
          margin: auto;
          .btnMinus,
          .btnPlus {
            height: 50px;
            width: 50px;
            border-radius: 10px;
            border: 1px solid $border-color;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 30px;
            font-weight: bold;
            line-height: 1;
            @include btn-animate($primary-color, 100%, 250%, 260%, -260%);
          }
          .input {
            width: 80px;
            text-align: center;
            background: none;
            border: none;
            -moz-appearance: textfield;
            &::-webkit-outer-spin-button,
            &::-webkit-inner-spin-button {
              -webkit-appearance: none;
              margin: 0;
            }
          }
        }
        .amount {
          font-size: 22px;
          font-weight: bold;
        }
        .closeCircle {
          height: 30px;
          width: 30px;
          border-radius: 15px;
          display: flex;
          justify-content: center;
          align-items: center;
          border: 1px solid $border-color;
          top: -13px;
          right: -13px;
          background: white;
          @include btn-animate($primary-color, 100%, 250%, 260%, -260%);
          position: absolute;
          img {
            height: 12px;
          }
        }
      }
    }
    .footerButtons,
    .footerShowHide {
      display: none;
    }
  }
  .sideBar {
    flex: 0 0 25%;
    border-left: 1px solid $border-color;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .logo {
      width: 60%;
      margin-bottom: 70px;
    }
    .btnLogin,
    .btnRegister {
      width: 60%;
      height: 70px;
      border-radius: 15px;
      margin: 10px 0;
      background-color: $app-color;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 22px;
      font-weight: bold;
      color: white;
      @include btn-animate(white);
    }
    .btnLogin {
      border: 1px solid $border-color;
      background: white;
      color: $app-color;
    }
    .bare {
      width: 60%;
      .btnLogin {
        margin-top: 30px;
        width: 100%;
      }
    }
  }
  @include mdportrait {
    .pageContent {
      .header {
        padding: 50px 30px;
        .texts {
          .titleHead {
            font-size: 25px;
          }
          .subTitleHead {
            font-size: 16px;
          }
        }
        .btnAsset {
          display: none;
        }
      }
      .cartList {
        .cartItmCard {
          flex: unset;
          height: unset;
          max-height: unset;
          border: none;
          margin: 20px 30px;
          padding: 0;
          flex-direction: column;
          .coinDetail {
            width: 100%;
            img {
              height: 50px;
              width: 50px;
              margin-right: 10px;
            }
            .cardText {
              .name {
                font-size: 22px;
                font-weight: bold;
              }
              .amountMobile {
                display: block;
                font-size: 18px;
                font-weight: 600;
                color: $primary-color;
              }
              .symbool {
                display: none;
              }
            }
          }
          .count {
            display: flex;
            margin: 10px auto 10px 0;
            .btnMinus,
            .btnPlus {
              height: 30px;
              width: 30px;
              border-radius: 5px;
              font-size: 20px;
            }
            .input {
              width: 40px;
            }
          }
          .amount {
            display: none;
          }
        }
      }
      .footerButtons {
        display: flex;
        flex-direction: column;
        padding: 10px 30px;
        .btnLogin,
        .btnRegister {
          width: 100%;
          height: 60px;
          border-radius: 10px;
          margin: 10px 0;
          background-color: $app-color;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 18px;
          font-weight: 700;
          color: white;
          @include btn-animate(white);
        }
        .btnLogin {
          border: 1px solid $border-color;
          background: white;
          color: $app-color;
        }
      }
      .footerShowHide {
        display: flex;
        flex: 0 0 50px;
        border-top: 1px solid $border-color;
        justify-content: center;
        align-items: center;
        font-size: 18px;
        font-weight: bold;
        img {
          margin-left: 10px;
          width: 17px;
          transition: all 400ms ease;
          &.true {
            transform: rotate(180deg);
          }
        }
      }
    }
    .sideBar {
      display: none;
      flex-direction: column;
      overflow-y: auto;
      &.true {
        display: flex;
        position: absolute;
        background: white;
        top: 80px;
        left: 0;
        right: 0;
        bottom: 51px;
        .logo {
          width: 80%;
          margin-bottom: 100px;
        }
        .btnLogin,
        .btnRegister {
          width: 80%;
        }
        .bare {
          width: 80%;
          .btnLogin {
            margin-top: 30px;
            width: 100%;
          }
        }
      }
    }
  }
}
