@import "../../static/scss/colors";
@import "../../static/scss/mixin";

.homeSidebar {
  flex: 0 0 400px;
  display: flex;
  flex-direction: column;
  border-right: 1px solid $border-color;
  position: relative;
  overflow: visible;
  .circle {
    z-index: 1;
    right: -15px;
    top: 35px;
    height: 30px;
    width: 30px;
    border-radius: 15px;
    background: white;
    z-index: 5;
    border: 1px solid $border-color;
    display: flex;
    justify-content: center;
    align-items: center;
    @include btn-animate($primary-color, 50px, 20px, 70px, -70px);
    position: absolute;
    img {
      height: 50%;
    }
  }
  .logoWrap {
    height: 100px;
    display: flex;
    align-items: center;
    .logo {
      margin-left: 35px;
      height: 30px;
      width: fit-content;
      path {
        fill: $primary-color;
      }
    }
  }
  .menuList {
    height: 0;
    flex: 1;
    padding: 0 35px;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    .menuItem {
      flex: 0 0 90px;
      display: flex;
      align-items: center;
      font-size: 22px;
      font-weight: 500;
      border-bottom: 1px solid $border-color;
      @include btn-animate;
      &:last-of-type {
        border: none;
      }
      &.true {
        font-weight: bold;
      }
    }
  }
  .footerBtns {
    display: flex;
    flex-direction: column;
    padding: 35px;
    .btnReg,
    .btnLogin {
      height: 60px;
      border-radius: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 600;
      font-size: 20px;
      background: $app-color;
      color: white;
      @include btn-animate($primary-color);
    }
    .btnLogin {
      border: 1px solid $border-color;
      background: white;
      color: $app-color;
      @include btn-animate(white);
      margin-bottom: 20px;
    }
  }
  @include mdportrait {
    flex: unset;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 1;
    background: white;
    .circle {
      display: none;
    }
  }
}
