@import "../../static/scss/colors";
@import "../../static/scss/mixin";

.explore {
  margin-top: 100px;
  padding: 30px 40px;
  background: $primary-color;
  .expTitle {
    font-size: 28px;
    font-weight: 600;
    color: white;
  }
  .hubList {
    padding: 30px 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .hubCard {
      flex: 0 0 18%;
      height: 170px;
      margin: 20px 0;
      border-radius: 15px;
      background-size: cover;
      overflow: hidden;
      @include btn-animate;
      .shade {
        height: 100%;
        width: 100%;
        background: rgba(34, 33, 40, 0.57);
        display: flex;
        justify-content: center;
        align-items: center;
        span {
          font-size: 22px;
          font-weight: bold;
          color: white;
        }
      }
    }
  }
  @include mdportrait {
    padding: 30px 0;
    .expTitle {
      text-align: center;
    }
    .hubList {
      flex-wrap: nowrap;
      overflow-x: auto;
      .hubCard {
        min-width: 300px;
        margin: 0 15px;
      }
    }
  }
}
