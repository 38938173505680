@import "../../static/scss/colors";
@import "../../static/scss/mixin";
@import "../../static/scss/hamburgers";

.navWrap {
  display: flex;
  flex-direction: column;
  flex: 0 0 100px;
  border-bottom: 1px solid $border-color;
  background-color: white;
  transition: all 400ms ease;
  z-index: 1;
  top: 0;
  left: 0;
  right: 0;
  position: fixed;
  .navBar {
    display: flex;
    align-items: center;
    flex: 0 0 100px;
    height: 100px;
    position: relative;
    padding-left: 40px;
    @include use-hamburger(white, white, 30px);
    .hamburger {
      margin: auto 40px;
    }
    .logo {
      height: 30%;
      path {
       // fill: white;
      }
    }
    .user {
      margin-left: auto;
      height: 50px;
      display: flex;
      border: 1px solid $border-color;
      border-radius: 12px;
      display: flex;
      align-items: center;
      padding: 8px;
      margin-right: 80px;
      .dp {
        height: 34px;
        width: 34px;
        border-radius: 17px;
        margin-right: 8px;
      }
      span {
        font-size: 24px;
        font-weight: 600;
      }
    }
  }

  &.true {
    .navBar {
      @include use-hamburger($primary-color, $primary-color, 30px);
      .hamburger {
        margin: auto 40px;
      }
      .logo {
        // path {
        //   fill: $primary-color;
        // }
      }
    }
  }
  @include mdportrait {
    flex: 0 0 80px;
    .navBar {
      flex: 0 0 80px;
      height: 80px;
      padding-left: 0;
      .logo {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        height: 40%;
      }
      .user {
        display: none;
      }
    }
  }
}

.spacer {
  flex: 0 0 100px;
  @include mdportrait {
    flex: 0 0 80px;
  }
}
