@import "../../static/scss/colors";
@import "../../static/scss/mixin";

.registerPage {
  height: 0;
  flex: 1;
  display: flex;
  .sidebar {
    flex: 0 0 30%;
    background: $app-color;
    padding: 10% 5%;
    display: flex;
    align-items: center;
    .title {
      color: white;
      font-size: 68px;
      font-weight: 600;
      line-height: 1.6;
    }
  }
  .contentArea {
    flex: 1;
    padding: 10% 20%;
    display: flex;
    flex-direction: column;
    align-items: center;
    .logo {
      width: 100%;
    }
    .subTitle {
      font-size: 24px;
      text-align: center;
      font-weight: 600;
      margin: 10% 0;
    }
    .inpWrap {
      height: 90px;
      width: 100%;
      border-radius: 20px;
      border: 1px solid $border-color;
      display: flex;
      .input {
        width: 0;
        flex: 1;
        padding: 0 40px;
        font-size: 20px;
        border: none;
        background: none;
      }
      .paste {
        width: 100px;
        padding: 25px;
        cursor: pointer;
      }
    }
    .user {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 8% 0;
      .profileImg {
        height: 210px;
        width: 210px;
        border-radius: 50%;
        margin-bottom: 20px;
      }
      .name {
        line-height: 1;
        font-size: 32px;
        font-weight: 600;
        margin-bottom: 20px;
      }
      .email {
        font-size: 24px;
        line-height: 1;
      }
    }
    .footerBtns {
      margin-top: 10%;
      width: 100%;
      display: flex;
      height: 80px;
      justify-content: space-between;
      .btnReg,
      .btnLogin {
        flex: 0 0 49%;
        border-radius: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 600;
        font-size: 20px;
        background: $app-color;
        color: white;
        @include btn-animate(white);
      }
      .btnLogin {
        border: 1px solid $border-color;
        background: white;
        color: $app-color;
        @include btn-animate($primary-color);
      }
    }
  }
}
