@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;800&display=swap");

@import "_colors";
@import "mixin";
html {
  min-height: 100vh;
  min-height: -webkit-fill-available;
  max-height: 100vh;
  max-height: -webkit-fill-available;
  display: flex;
  flex-direction: column;
  body {
    height: 0;
    flex: 1;
    font-family: Montserrat !important;
    margin: 0;
    display: flex;
    flex-direction: column;
    scroll-behavior: smooth;
    #root {
      height: 0;
      flex: 1;
      display: flex;
      flex-direction: column;
      color: $primary-color;
      * {
        font-family: Montserrat;
        outline: none;
        box-sizing: border-box;
      }
      @media (max-height: 1050px) {
        zoom: 0.95;
      }
      @include xxxl {
        zoom: 0.8;
      }
      @include xxl {
        zoom: 0.7;
      }
      @include xl {
        zoom: 0.6;
      }
      @include lg {
        zoom: 0.5;
      }
      @include mdlscape {
        zoom: 0.35;
      }
      @include smlscape {
        zoom: 0.25;
      }
      @include mdportrait {
        zoom: 1;
      }
    }
  }
}
::-webkit-scrollbar {
  width: 0;
  height: 0;
}
// Opcity Of Cards Carousel
.css-1fzpoyk {
  opacity: 1 !important;
}
