@import "../../static/scss/colors";
@import "../../static/scss/mixin";

.paymentSidebar {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  .content {
    height: 0;
    flex: 1;
    padding: 40px;
    display: flex;
    flex-direction: column;
    .header {
      margin-bottom: 40px;
      .title {
        font-size: 26px;
        font-weight: bold;
      }
      .breadCrumbs {
        margin-top: 6px;
        font-size: 16px;
        span {
          &:last-of-type {
            font-weight: 600;
            text-decoration: underline;
          }
        }
      }
    }
    .searchWrap {
      height: 60px;
      width: 100%;
      padding: 0 30px;
      border: 1px solid $border-color;
      border-radius: 10px;
      font-weight: 600;
      margin-bottom: 40px;
    }
    .actionList {
      height: 0;
      flex: 1;
      display: flex;
      flex-direction: column;
      overflow-y: auto;
      .card {
        height: 120px;
        border: 1px solid $border-color;
        border-radius: 10px;
        overflow: hidden;
        margin-bottom: 20px;
        display: flex;
        cursor: pointer;
        &:hover {
          background: whitesmoke;
        }
        &.true {
          border-color: black;
          background: whitesmoke;
        }
        .sider {
          width: 15px;
        }
        .cardIcon {
          height: 35%;
          margin: auto;
        }
      }
      .cardAppCoin {
        flex: 0 0 100px;
        border: 1px solid $border-color;
        border-radius: 10px;
        overflow: hidden;
        margin-bottom: 20px;
        display: flex;
        padding: 0 30px;
        align-items: center;
        cursor: pointer;
        &:hover {
          background: whitesmoke;
        }
        &.true {
          border-color: black;
          background: whitesmoke;
        }
        .icon {
          height: 30px;
          width: 30px;
          object-fit: contain;
          margin-right: 10px;
        }
        .cardName {
          flex: 1;
          font-size: 22px;
          font-weight: 600;
          display: flex;
          justify-content: space-between;
          span {
            font-weight: 400;
          }
        }
      }
    }
  }
  .footer {
    margin-top: auto;
    height: 70px;
    min-height: 70px;
    border-top: 1px solid $border-color;
    display: flex;
    .footerBtnBack,
    .footerBtnNext {
      flex: 0 0 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 22px;
      font-weight: 600;
      @include btn-animate;
    }
    .footerBtnNext {
      background-color: $app-color;
      color: white;
      @include btn-animate(white);
    }
  }
  .headerArea {
    flex: 0 0 160px;
    background-color: $app-color;
    margin-bottom: 70px;
    position: relative;
    .img {
      height: 140px;
      width: 140px;
      border-radius: 70px;
      position: absolute;
      border: 1px solid $border-color;
      left: 0;
      right: 0;
      bottom: -70px;
      margin: auto;
      background: white;
      padding: 30px;
      img {
        height: 100%;
        width: 100%;
      }
    }
  }
  .headerPreview {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    .title {
      font-size: 26px;
      font-weight: 600;
    }
  }
  .detail {
    padding: 20px 40px;
    .deatailTitle {
      font-size: 18px;
      font-weight: 600;
      margin-bottom: 20px;
    }
    .details {
      border: 1px solid $border-color;
      border-radius: 10px;
      padding: 0 40px;
      .detailItem {
        height: 70px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-top: 1px solid $border-color;
        .coin {
          display: flex;
          align-items: center;
          .coinImage {
            height: 25px;
            width: 25px;
            object-fit: contain;
            margin-right: 5px;
          }
          span {
            font-size: 20px;
            font-weight: 600;
          }
        }
        .label {
          font-size: 20px;
          font-weight: 500;
        }
        .value {
          font-size: 20px;
          font-weight: 600;
        }
        &:first-of-type {
          border: none;
        }
      }
    }
  }
  .success {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 35px;
    line-height: 1.8;
    font-weight: 600;
  }
  .successFooter {
    flex: 0 0 90px;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
  }
  .footerHide {
    display: none;
  }
  @include mdportrait {
    .content {
      .header {
        margin-bottom: 20px;
        .title {
          font-size: 20px;
        }
        .breadCrumbs {
          font-size: 13px;
        }
      }
      .searchWrap {
        padding: 0 20px;
        height: 50px;
        font-size: 14px;
      }
      .actionList {
        height: 0;
        flex: 1;
        .card {
          height: 90px;
        }
        .cardAppCoin {
          flex: 0 0 80px;
          .icon {
            height: 26px;
            width: 26px;
          }
          .cardName {
            font-size: 18px;
          }
        }
      }
    }
    .headerArea {
      flex: 0 0 100px;
      margin-bottom: 50px;
      .img {
        height: 90px;
        width: 90px;
        border-radius: 45px;
        bottom: -45px;
        padding: 10px;
      }
    }
    .headerPreview {
      padding: 5px 10px;
      .title {
        font-size: 22px;
      }
    }
    .detail {
      .deatailTitle {
        font-size: 16px;
        margin-bottom: 16px;
      }
      .details {
        padding: 0 20px;
        .detailItem {
          height: 60px;
          .coin {
            .coinImage {
              height: 20px;
              width: 20px;
            }
            span {
              font-size: 17px;
            }
          }
          .label {
            font-size: 17px;
          }
          .value {
            font-size: 17px;
          }
        }
      }
    }
    .success {
      font-size: 20px;
    }
    .successFooter {
      font-size: 14px;
    }
    .footerHide {
      display: flex;
      position: fixed;
      left: 0;
      right: 0;
      bottom: 0;
      height: 76px;
      background: white;
      z-index: 1;
    }
  }
}
