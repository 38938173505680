@import "../../static/scss/colors";
@import "../../static/scss/mixin";
@import "../../static/scss/hamburgers";

.navWrap {
  display: flex;
  flex-direction: column;
  flex: 0 0 100px;
  border-bottom: 1px solid $border-color;
  top: 0;
  left: 0;
  right: 0;
  position: fixed;
  background-color: $primary-color;
  transition: all 400ms ease;
  z-index: 1;
  .navBar {
    display: flex;
    align-items: center;
    flex: 0 0 100px;
    height: 100px;
    position: relative;
    @include use-hamburger(white, white, 30px);
    .hamburger {
      margin: auto 40px;
      transition: all 400ms ease;
      &:hover {
        transform: scale(0.9);
      }
    }
    .logo {
      height: 30%;
      // path {
      //   fill: white;
      // }
    }
    .searcWrap {
      margin-left: 60px;
      height: 40px;
      width: 20%;
      border-radius: 8px;
      background: #f8f8f8;
      border: 1px solid $border-color;
      overflow: hidden;
      .search {
        padding: 0 25px;
        height: 100%;
        width: 100%;
        border: none;
        background: none;
      }
    }
    .btnLogin {
      //border: 1px solid white;
      margin-left: auto;
      margin-right: 40px;
      color: white;
      padding: 10px 25px;
      font-size: 18px;
      font-weight: 600;
      border-radius: 6px;
      background: linear-gradient(118.35deg, #283790 32.48%, #ec207c 106.98%);
      border-radius: 10px;
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 42px;
      /* or 262% */

      display: flex;
      align-items: center;
      text-align: center;
      width: 132px;
      height: 42px;
      color: #ffffff;
      justify-content: center;
      // @include btn-animate(white);
    }
    .user {
      cursor: pointer;
      margin-left: auto;
      height: 50px;
      display: flex;
      border: 1px solid $primary-color;
      border-radius: 12px;
      display: flex;
      align-items: center;
      padding: 8px;
      margin-right: 80px;
      @include btn-animate(white);
      .dp {
        height: 34px;
        width: 34px;
        border-radius: 17px;
        margin-right: 8px;
      }
      span {
        color: white;
        font-size: 24px;
        font-weight: 600;
        transition: all 400ms ease;
      }
    }
  }
  .navDetail {
    display: flex;
    flex-direction: column;
    position: relative;
    .navDetailTexts {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: white;
      overflow: hidden;
      .subtitle {
        font-size: 28px;
        font-weight: 500;
        margin-bottom: 10px;
      }
      .title {
        font-size: 43px;
        font-weight: 600;
      }
    }
    .spacer {
      flex: 0 0 70px;
    }
    .searcWrap {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      height: 80px;
      width: 50%;
      border-radius: 20px;
      border: none;
      background: #f8f8f8;
      border: 1px solid $border-color;
      margin-bottom: -40px;
      display: flex;
      align-items: center;
      .input {
        height: 100%;
        width: 100%;
        border: none;
        background: none;
        font-size: 20px;
        font-weight: 600;
      }
      .icon {
        height: 40px;
        width: 40px;
        margin: auto 20px;
        opacity: 0.5;
        cursor: pointer;
        transition: all 400ms ease;
        &:hover {
          transform: scale(1.1);
        }
      }
      .spacerLine {
        height: 70%;
        border-left: 1px solid $border-color;
      }
      span {
        flex: 0 0 150px;
        font-size: 20px;
        font-weight: 600;
        white-space: nowrap;
        text-align: center;
        cursor: pointer;
        transition: all 400ms ease;
        &:hover {
          transform: scale(1.1);
        }
      }
    }
  }
  &.true {
    .navBar {
      @include use-hamburger($primary-color, $primary-color, 30px);
      .hamburger {
        margin: auto 40px;
      }
      .logo {
        // path {
        //   fill: $primary-color;
        // }
      }
      .btnLogin {
        border-color: $primary-color;
        color: $primary-color;
        @include btn-animate();
      }
      .user {
        border-color: $border-color;
        @include btn-animate();
        span {
          color: $primary-color;
        }
      }
    }
  }
  &.sideOpen {
    left: 400px;
  }
  @include mdportrait {
    flex: 0 0 80px;
    .navBar {
      flex: 0 0 80px;
      height: 80px;
      z-index: 3;
      .logo {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        height: 40%;
      }
      .btnLogin,
      .user,
      .searcWrap {
        display: none;
      }
    }
    .navDetail {
      .navDetailTexts {
        padding: 0 40px;
        .subtitle {
          font-size: 14px;
          text-align: center;
          display: none;
        }
        .title {
          font-size: 22px;
          text-align: center;
        }
      }
      .spacer {
        flex: 0 0 60px;
      }
      .searcWrap {
        height: 60px;
        width: 80%;
        border-radius: 10px;
        margin-bottom: -30px;
        display: flex;
        .input {
          width: 0;
          flex: 1;
          font-size: 16px;
          order: 0;
          padding: 0 10px;
        }
        .icon {
          order: 1;
          height: 30px;
          width: 20px;
          margin: auto 10px;
          opacity: 1;
        }
        .spacerLine,
        span {
          display: none;
        }
      }
    }
  }
}
.fullNavSpacer {
  height: 96px;
  min-height: 96px;
  flex: 0 0 96px;
  @include mdportrait {
    height: 280px;
    min-height: 280px;
    flex: 0 0 280px;
  }
}
