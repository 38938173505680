@mixin use-hamburger(
  $burger-color: $primary-color,
  $burger-color-active: $primary-color,
  $size: 40px
) {
  .hamburger {
    display: inline-block;
    cursor: pointer;
    transition-property: opacity, filter;
    transition-duration: 0.15s;
    transition-timing-function: linear;
    font: inherit;
    color: inherit;
    text-transform: none;
    background-color: transparent;
    border: 0;
    margin: 0;
    overflow: visible;

    &.isActive {
      .hamburgerInner {
        background-color: #000;

        &::before,
        &::after {
          background-color: #000;
        }
      }
    }
  }

  .hamburgerBox {
    width: $size;
    height: $size;
    display: inline-block;
    position: relative;
  }

  .hamburgerInner {
    display: block;
    top: 50%;
    margin-top: -1px;
    width: $size;
    height: calc($size / 10);
        background-color: #000;
    border-radius: 1px;
    position: absolute;
    transition-property: transform;
    transition-duration: 0.15s;
    transition-timing-function: ease;

    &::before,
    &::after {
      width: $size;
      height: calc($size / 10);
          background-color: #000;
      border-radius: calc($size / 10);
      position: absolute;
      transition-property: transform;
      transition-duration: 0.15s;
      transition-timing-function: ease;
    }

    &::before,
    &::after {
      content: "";
      display: block;
    }

    &::before {
      top: calc($size / -3);
    }

    &::after {
      bottom: calc($size / -3);
    }
  }

  .hamburgerSqueeze {
    .hamburgerInner {
      transition-duration: 0.075s;
      transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);

      &::before {
        transition: top 0.075s 0.12s ease, opacity 0.075s ease;
      }

      &::after {
        transition: bottom 0.075s 0.12s ease,
          transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19);
      }
    }

    &.isActive .hamburgerInner {
      transform: rotate(45deg);
      transition-delay: 0.12s;
      transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
      background-color: #000;

      &::before {
        top: 0;
        opacity: 0;
        transition: top 0.075s ease, opacity 0.075s 0.12s ease;
        background-color: #000;
      }

      &::after {
        bottom: 0;
        transform: rotate(-90deg);
        transition: bottom 0.075s ease,
          transform 0.075s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
          background-color: #000;
      }
    }
  }
}
