.sectionTop {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: 7rem;

  padding-bottom: 7rem;
  h1 {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 800;
    font-size: 80px;
    line-height: 80px;
    margin-top: 0px;
    text-align: center;

    background: linear-gradient(178.38deg, #283790 1.37%, #ec207c 107.54%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  p {
    width: 834px;
    height: 98px;

    font-family: "Montserrat";
    font-style: normal;
    font-weight: 300;
    font-size: 25px;
    line-height: 40px;
    /* or 160% */

    text-align: center;

    color: #222128;
  }
}

.SectionSecond {
  display: flex;
  align-items: flex-start;
  width: 100%;
  position: relative;
  .overlap {
    position: absolute;
    width: 100%;
    background-color: #000;
    height: 340px;
    background-color: #000000a1;
  }
  h1 {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 800;
    font-size: 40px;
    line-height: 50px;
    position: relative;
    z-index: 1;
    color: #ffffff;
  }
  .second {
    width: 50%;
    display: flex;
    align-items: flex-end;
    height: 340px;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url("../../static/images/bgs/one.png");
    // background: #000000;
    padding-left: 40px;
  }
  .first {
    display: flex;
    align-items: flex-end;
    height: 340px;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url("../../static/images/bgs/two.png");
    //  background: #000000;
    //  opacity: 0.5;
    width: 50%;
      padding-left: 40px;
  }
}
